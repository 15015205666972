// Load Styles
import '../scss/main.scss';
import {initBootstrap} from "./bootstrap.js";
import './animations.js';


// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

