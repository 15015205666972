import { TypeShuffle } from "./typeShuffle.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger); 

  document.addEventListener("DOMContentLoaded", function (event) {
    console.log("DOM loaded");
    //wait until images, links, fonts, stylesheets, and js is loaded
    window.addEventListener(
      "load",
      function (e) {
        //START FUNCTION

        // TOP BG GRADIENT FX
        const b1 = "linear-gradient(217deg, rgba(0,214,112,.9), rgba(0,214,112,0) 70.71%),  linear-gradient(127deg, rgba(255,132,41,.9), rgba(0,214,112,0) 70.71%), linear-gradient(336deg, rgba(204,218,70,.9), rgba(255,132,41,0) 70.71%)";
        const b2 = "linear-gradient(17deg, rgba(0,214,112,.7), rgba(0,214,112,0) 70.71%), linear-gradient(200deg, rgba(106,37,53, .9), rgba(255,132,41,.2) 70.71%),  linear-gradient(336deg, rgba(204,218,70,.8), rgba(255,132,41,1) 70.71%)";
        gsap.fromTo(".top-bg", {background: b1}, {ease: "none", duration: 3, background: b2, repeat: -1, yoyo: true});

        // TOP TYPE SHUFFLE
        function initTypeShuffle() {
          const textElement = document.querySelector(".first-content");
          textElement.style.opacity = 1;
          const ts = new TypeShuffle(textElement);
          ts.trigger("fx3");
          var tlNav = gsap.timeline({});
          tlNav.to(".contact-top", {
            duration: 1,
            opacity: 1,
            ease: "power2",
            delay: 2
          })
          .to(".c-scrolldown.first", {
            duration: 1,
            opacity: 1,
            ease: "power2",
          })
        }
        
        //LOGO FADE
        var tlInitial = gsap.timeline({});
        tlInitial.fromTo(".top-bg", {
          opacity: 0,
          y: 100,
        }, {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power2",
        })
        .fromTo(".logo", {
          opacity: 0,
          y: -100,
        }, {
          opacity: 1,
          y: 0,
          duration: 0.75,
          ease: "power2",
          onComplete:initTypeShuffle,
        });

        // SECOND CONTENT SHUFFLE
        const secondContent = document.querySelector(".second-scroll");
        const textSecondElement = document.querySelector(".second-content");
        function initTypeShuffle2() {
          textSecondElement.style.opacity = 1;
          const ts2 = new TypeShuffle(textSecondElement);
          ts2.trigger("fx3");
          var tlBottom = gsap.timeline({});
          tlBottom.from(".vectis-icon", {
            duration: 1,
            opacity: 0,
            y: 50,
            ease: "power2",
            delay:1.5
          })
          .fromTo(".contact-us-bottom", {
            opacity: 0,
            y: -100
          }, {
            duration: 1,
            opacity: 1,
            ease: "power2",
            y: 0
          });
        }

        // SECOND CONTENT SHUFFLE TRIGGER
        gsap.from(secondContent, {
            scrollTrigger: {
              trigger: secondContent,
              scrub: 1,
              // markers: true,
              start: "top 80%",
              end: "top 30%",
              ease: "power2",
              once: true,
              onEnter: initTypeShuffle2
            },
          }
        );

        // MARQUEE
        const parentSelector1 = document.querySelector(".marquee-1");
        const clone1 = parentSelector1.innerHTML;
        const firstElement1 = parentSelector1.children[0];
        let i1 = 0;
        parentSelector1.insertAdjacentHTML('beforeend', clone1);
        parentSelector1.insertAdjacentHTML('beforeend', clone1);
        firstElement1.style.marginLeft = `-${i1}px`;
        if (i1 > firstElement1.clientWidth) {
          i1 = 0;
        }
        i1 = i1
        
        const parentSelector2 = document.querySelector(".marquee-2");
        const clone2 = parentSelector2.innerHTML;
        const firstElement2 = parentSelector2.children[0];
        let i2 = 0;
        parentSelector2.insertAdjacentHTML('beforeend', clone2);
        parentSelector2.insertAdjacentHTML('beforeend', clone2);
        firstElement2.style.marginLeft = `-${i2}px`;
        if (i2 > firstElement2.clientWidth) {
          i2 = 0;
        }
        i2 = i2

        const parentSelector3 = document.querySelector(".marquee-3");
        const clone3 = parentSelector3.innerHTML;
        const firstElement3 = parentSelector3.children[0];
        let i3 = 0;
        parentSelector3.insertAdjacentHTML('beforeend', clone3);
        parentSelector3.insertAdjacentHTML('beforeend', clone3);
        firstElement3.style.marginLeft = `-${i3}px`;
        if (i3 > firstElement3.clientWidth) {
          i3 = 0;
        }
        i3 = i3
      
        const marquees = gsap.utils.toArray(".marquee");
        marquees.forEach((marquee, index) => {
          
        const [x, xEnd] =
          index % 2
            ? [
                Math.floor(Math.random() * (80 - 70) + 70) * -1 + "%",
                Math.floor(Math.random() * (30 - 20) + 20) * -1 + "%",
              ]
            : [
                Math.floor(Math.random() * (30 - 20) + 20) * -1 + "%",
                Math.floor(Math.random() * (70 - 60) + 60) * -1 + "%",
              ];
        // console.log(x);
        // console.log(xEnd);
        gsap.fromTo(
          marquee,
          { x },
          {
            x: xEnd,
            scrollTrigger: {
              trigger: marquee,
              scrub: 1,
              start: "top bottom",
              end: "bottom top",
              ease: "power2",
            },
          }
        );
      });


      

        // END FUNCTION
      },
      false
    );
  });
